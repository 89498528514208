.recommendedVideos{
  flex: 0.8;
  background-color:#f9f9f9;
  padding: 40px 20px;
  padding-bottom: 0px;
  height: 100vh;
  overflow-y: scroll;
}

.recommendedVideosSmall {
  flex: 1;
  background-color: #f9f9f9;
  padding: 40px 20px;
  padding-bottom: 0px;
  height: 100vh;
  overflow-y: scroll;
}

.recommendedVideos-dark {
  background-color: #191919;
}

.recommendedVideos > h2 {
  margin-left: 5px;
  margin-bottom: 20px;
}

.recommendedVideos-dark>h2 {
  color:white;
}

.recommendedVideos-videos {
  display:flex;
  flex-wrap: wrap;
}
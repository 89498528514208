.header-optionsIcon {
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #909090 !important;
}

.header-optionsIcon-item {
  margin-left: 20px;
  color: #909090 !important;
}

.header-optionsIconText {
  width: 90%;
  margin-left: 20px;
  color: #000000;
  font-weight: 350;
  font-size: 14px;
}

.header-optionsIconText-dark {
  color: white;
}

.header-optionsIconText:hover {
  cursor: pointer;
}

.header-optionsIcon-itemArrow {
  justify-content: flex-end !important;
  color: #909090 !important;
  font-size: 15px !important;
}

.headerOptions-icon-dark {
  color: white !important;
}
.searchPage {
flex:0.8;
background-color: #f9f9f9;
padding:20px;
}

.searchPageSmall {
  flex:1;
  background-color: #f9f9f9;
  padding:20px;

}

.searchPage-dark {
  background-color: #191919;
}

.searchPage-filter {
  display:flex;
  align-items:center;
  color:#606060;
  font-size: xx-small !important;
}

.searchPage-filter-dark {
  color: #aaaaaa;
}

.searchPage-filter > h2 {
  margin-left: 10px;
}

.searchPage-hr {
  height: 1px;
  border: 0;
  background-color: lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
}
.sidebarRow {
  display:flex;
  align-items:center;
  padding:10px 20px;
}

.sidebarRow-icon {
  color:#606060;
  font-size: 25px !important;
}

.sidebarRow-title {
  flex: 1;
  margin-left: 20px;
  font-size:14px;
  font-weight: 500;
}

.sidebarRow-title {
  color:#000000
}

.sidebarRow-title-dark {
  color: white;
}

.sidebarRow:hover {
  background-color:#e5e5e5;
  cursor:pointer;
}

.sidebarRow-dark:hover {
  background-color:#383838
}

.sidebarRow:hover > .sidebarRow-icon {
  color: red;
}

.sidebarRow-dark:hover>.sidebarRow-icon-dark {
  color: white;
}

.sidebarRow:hover > .sidebarRow-title {
  font-weight: bold;
}

.sidebarRow.selected {
  background-color:#e5e5e5;
}

.sidebarRow-dark.selected {
  background-color: #383838
}

.sidebarRow.selected>.sidebarRow-icon {
  color: red;
}

.sidebarRow-dark.selected>.sidebarRow-icon-dark {
  color: white;
}

.sidebarRow.selected>.sidebarRow-title {
  font-weight: bold;
}
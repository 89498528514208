.videoRow {
  display: flex;
  margin-bottom: 30px;
  max-width: 700px;
}

.videoRow > img {
  object-fit: contain;
  width:246px;
  height:138px;
}

.videoRow-text {
  margin-left: 14px;
}

.videoRow-text-dark > h3 {
  color: #fff;
  font-weight: 500;
}

.videoRow-headline {
  font-size: 12px;
  color: #606060;
}

.videoRow-headline-dark {
  color: #878787;
}


.videoRow-subsNumber {
  font-weight: bolder;
  color: rgb(84, 157, 211)
}

.videoRow-channelDetils {
  display: flex;
  align-items: center;
}

.videoRow-channelDetils > p {
  margin-left: 10px;
  font-size: 12px;
  color: #606060;
}

.videoRow-channelDetils-dark > p {
  color: #878787
}

.videoRow-description {
  margin-top: 20px;
  font-size: 12px;
  color: #606060
}

.videoRow-description-dark {
  color: #878787;
}

.videoRow-dot {
  color: gray;
  font-size: 18px;
}
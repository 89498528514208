.sidebar{
  flex: 0.2;
  overflow-y: scroll;
  height: 100vh;
}

.sidebar > hr {
  height: 1px;
  border: 0;
  background-color: lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar-text {
  margin-left: 20px;
  color: #797979;
  font-size: 14px;
  font-weight: bold;
}

.sidebar-linksSection {
  margin-top:15px;
}

.sidebar-links {
  margin-bottom: 5px;
  text-align: center;
}

.sidebar-linkItems {
  color: #6b6b6b;
  font-weight: 750;
  font-size:12px;
  padding-left:5px;
}

.sidebar-linkItems:hover {
  cursor: pointer;
}

.sidebar-copyright {
  margin: 20px 0px;
  text-align: center;
}

.sidebar-copyright > p {
  color: #bdbdbd;
  font-weight: 600;
  font-size:12px;
}
.channelRow {
  display:flex;
  align-items:center;
  width:70%;
}

.channelRow-logo {
  height: 120px !important;
  width: 120px !important;
  margin: 10px 60px;
}

.channelRow-text {
  display:flex;
  flex-direction: column;
}

.channelRow-text > p {
  color: #606060;
  font-size:small !important;
}

.channelRow-text > h4 {
  display:flex;
  align-items:center;
  justify-content:space-between;
}

.channelRow-text-dark > p {
  color:#878787;
}

.channelRow-text-dark > h4 {
  color:#878787;
}

.channelRow-dot {
  color: gray;
  font-size: 18px;
}
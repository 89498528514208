.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

.header-dark {
  background-color: #212121;
}

.header-logo {
  height: 25px;
  object-fit: contain;
  margin-left: 20px;
}

.header-inputContainer {
  display: flex;
  align-items: center;
  width: 45%;
}

.header-inputContainer-dark {
  display: flex;
  align-items: center;
  width: 45%;
}

.header-input {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid lightgray;
}

.header-input-dark {
  display: flex;
  align-items: center;
  width: 100%;
  background-color:black;
  border: 1px solid transparent;
}

.header-input-dark > input {
  background-color: black;
  flex: 1;
  border:none;
}

.header-input > input {
  flex: 1;
  border:none;
}


.header-searchInputButton {
  width: 50px !important;
  background-color: #fafafa;
  border-left: 1px solid lightgray;
  color: gray;
}

.header-small-searchInputButton {
  width: 50px !important;
  color: gray;
}

.header-micInputButton {
  width: 50px !important;
  color: #606060 !important;
}

.header-searchInputButton-dark {
  width: 50px !important;
  background-color: #323232;
  border-left: 1px solid transparent;
  color: #606060 !important;
}

.header-small-searchInputButton-dark {
  width: 50px !important;
  color: white !important;
}

.header-micInputButton-dark {
  width: 50px !important;
  color: white;
}

.header-input-searchLink-button {
  padding: 0px !important;
  margin: 0px !important;
}

.header-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options-popover {
}

.header-icon {
  margin-right: 8px;
  color: #000000 !important;
}

.header-icon-dark {
  color:white !important;
}

.header-iconText {
  color: #6b6b6b;
  font-weight: 750;
  font-size:12px;
}

.header-iconText-dark {
  color: white;
}

.header-iconText:hover {
  cursor: pointer;
}

.header-options {
  width: 300px;
}

.header-options-dark {
  background-color: #212121;
}

.header-optionsIcon {
  display:flex;
  align-items: center;
  margin-right: 8px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #909090 !important;
}

.header-optionsIcon-item {
  margin-left: 20px;
  color: #909090 !important;
}

.header-optionsIconText {
  width: 90%;
  margin-left: 20px;
  color: #000000;
  font-weight: 350;
  font-size: 14px;
}

.header-optionsIconText-dark {
  color: white;
}

.header-optionsIconText:hover {
  cursor: pointer;
}

.header-optionsIcon-itemArrow {
  justify-content:flex-end !important;
  color: #909090 !important;
  font-size: 15px !important;
}

hr {
  height: 1px;
  border: 0;
  background-color: #383838;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-signInButton>button {
  font-weight: bold;
  color: #085fd4;
  border: 1px solid #085fd4;
}

.header-signInButton-dark>button {
  color:#3ea6ff;
  border: 1px solid #3ea6ff;
}

.header-signInButton-icon {
  color: #085fd4;
  font-size: 27px !important;
}

.header-signInButton-icon-dark {
  color:#3ea6ff
}

.header-options-userDetails {
  display: flex;
  padding-top: 20px;
}

.header-options-userDetails-avatar {
  margin-left: 10px;
}

.header-options-details {
  justify-content: flex-end;
  margin-left: 20px;
}

.header-options-details-dark > p{
  color: white;
}
.videoCard {
  margin-bottom: 40px;
  width: 270px;
}

.videoCard-image {
  height: 140px;
  width: 250px;
}

.video-info {
  display: flex;
  margin-top: 10px;
  padding-right: 30px;
}

.videoCard-avatar {
  height: 30px !important;
  width: 30px !important;
}

.videoCard-text {
  margin-left: 15px;
}

.videoCard-text > h4 {
  font-size:14px;
  margin-bottom: 5px;
}

.videoCard-text > p {
  font-size:14px;
  color: gray;
}

.videoCard-text-dark {
  color: white;
}

.videoCard-dot {
  color: gray;
  font-size: 18px;
}
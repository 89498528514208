.headerSearch {
  display: flex;
  align-items: center;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

.headerSearch-dark {
  background-color: #212121;
}

.headerSearch-inputContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.headerSearch-inputContainer-dark {
  display: flex;
  align-items: center;
}

.headerSearch-input {
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid lightgray;
}

.headerSearch-input-dark {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: black;
  border: 1px solid transparent;
}

.headerSearch-input-dark>input {
  background-color: black;
  flex: 1;
  border: none;
}

.headerSearch-input>input {
  flex: 1;
  border: none;
}


.headerSearch-searchInputButton {
  width: 50px !important;
  background-color: #fafafa;
  border-left: 1px solid lightgray;
  color: gray;
}

.headerSearch-micInputButton {
  width: 50px !important;
  color: #606060 !important;
}

.headerSearch-searchInputButton-dark {
  width: 50px !important;
  background-color: #323232;
  border-left: 1px solid transparent;
  color: #606060 !important;
}

.headerSearch-micInputButton-dark {
  width: 50px !important;
  color: white;
}

.headerSearch-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options-popover {}

.headerSearch-icon {
  margin-right: 8px;
  color: #000000 !important;
}

.headerSearch-icon-dark {
  color: white !important;
}

.headerSearch-input-searchLink-button {
    padding: 0px !important;
    margin: 0px !important;
}